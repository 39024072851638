import { getCookie } from "./helpers";

// Funkcja do dodawania Google Analytics (gtag.js)
export const addGTag = (taggingServer, gtagId) => {
  // Utworzenie skryptu dla gtag.js
  const scriptTag = document.createElement('script');
  scriptTag.async = true;
  scriptTag.src = `${taggingServer}gtag/js?id=${gtagId}`;
  document.head.appendChild(scriptTag);

  const adStorage = getCookie('ad_storage') || 'denied';
  const adUserData = getCookie('ad_user_data') || 'denied';
  const adPersonalization = getCookie('ad_personalization') || 'denied';
  const analyticsStorage = getCookie('analytics_storage') || 'denied';


  // Utworzenie skryptu inicjalizującego gtag
  const scriptTagInit = document.createElement('script');
  const scriptContent = document.createTextNode(`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
  
      const framkyDomains = [
        "framky.com",
        "framky.pl",
        "framky.it",
        "framky.de",
        "framky.fr",
        "framky.nl",
        "framky.dk",
        "framky.se",
        "framky.es",
        "framky.cz",
        "studio.framky.com",
        "studio.framky.pl",
        "studio.framky.it",
        "studio.framky.de",
        "studio.framky.fr",
        "studio.framky.nl",
        "studio.framky.dk",
        "studio.framky.se",
        "studio.framky.cz",
        "studio.framky.es"
      ];

      gtag('consent', 'default', {
          'ad_storage':'denied',
          'ad_personalization': 'denied',
          'ad_user_data': 'denied',
          'analytics_storage': 'denied',
          wait_for_update: 500
      });

      gtag('set', 'linker', {'domains': framkyDomains});
  
      gtag('js', new Date());
  
      gtag('config', '${gtagId}', {
        server_container_url: '${taggingServer}',
        allow_google_signals: true,
        first_party_collection: true,
      });      
    `);

  const consentUpdate = document.createTextNode(`
          gtag('consent', 'update', {
          'ad_storage':'${adStorage}',
          'ad_personalization': '${adPersonalization}',
          'ad_user_data': '${adUserData}',
          'analytics_storage': '${analyticsStorage}'
      });
  `);
  scriptTagInit.appendChild(scriptContent);
  if (adStorage !== 'denied' || adUserData !== 'denied' || adPersonalization !== 'denied' || analyticsStorage !== 'denied') {
    scriptTagInit.appendChild(consentUpdate);
  }
  document.head.appendChild(scriptTagInit);

  console.log(`Added gtag code for ${gtagId}`);
};
